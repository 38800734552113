import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import Swal from "sweetalert2";

import ButtonNovo from "../../component/ButtonRed";
import logo from "../../logo_completa.png";

import MudancaFuncao from "../../component/DetalhesAtendimento/MudancaFuncao";
import Demissao from "../../component/DetalhesAtendimento/Demissao";
import Admissao from "../../component/DetalhesAtendimento/Admissao";

import api from "../../services/api";
//import FileUpload from '../upload';
//import Comentarios from '../../component/comentarios';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
}));

export default function InteragirAtendimento({ match, history }) {
  const classes = useStyles();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let id = match.params.id;

  const [labelWidth] = React.useState(0);
  const [data, setData] = useState({});
  //const [approved, setApproved] = useState(false);
  const [status, setStatus] = useState("1");

  const [loginData, setLoginData] = useState([]);
  const [userAgente, setUerAgente] = useState("");

  const inputLabel = React.useRef(null);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/login`);
      setLoginData(response.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`tiposatendimento/${id}/atendimentoid`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (status !== "1" && userAgente !== "") {
        await api.put(`/atendimento/${id}`, {
          atendimento_id: id,
          status: status,
          user_responsavel: userAgente,
          cpf: null,
        });

        Swal.fire({
          //position: 'top-start',
          icon: "success",
          title: `Atendimento ${status} com sucesso.`,
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          history.push("/dashboard");
        }, 500);
      }
    }
    fetchData();
  }, [status, userAgente, id, history]);

  let tipoAtendimento = data.tipo_atendimento || "";

  //const [value, setValue] = React.useState(<Comentarios />);

  //const handleChange = (event, newValue) => {
  //setValue(newValue);
  //};

  const handleReject = (e) => {
    e.preventDefault();
    setStatus("REJEITADO");
    setUerAgente(data?.user_id_solicitante?.id);
  };

  const handleApproved = (e) => {
    e.preventDefault();
    if (userAgente === "") {
      Toast.fire({
        icon: "error",
        title:
          "Para aprovar o atendimento deve-se selecionar o agente responsável.",
      });
    } else {
      setStatus("APROVADO");
    }
  };

  const handleChangeUser = (event) => {
    setUerAgente(event.target.value);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="App-logo" alt="logo" height="100" />
        <Typography component="h1" variant="h5">
          Atendimento #{id}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="Filial"
                name="Filial"
                variant="outlined"
                required
                fullWidth
                id="filail"
                color="secondary"
                label="Filial"
                value={data.filial || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="TipoAtendimento"
                color="secondary"
                label="Tipo de Atendimento"
                name="TipoAtendimento"
                value={tipoAtendimento.nome_atendimento || ""}
                disabled
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="data"
                color="secondary"
                label="Data da solicitação"
                name="data"
                value={data.data || ""}
                disabled
                autoComplete="data"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                color="secondary"
                name="Status"
                label="Status do Atendimento"
                id="Status"
                value={data.status || ""}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              {tipoAtendimento?.nome_atendimento === "Mudança de função" ? (
                <MudancaFuncao id={id} />
              ) : (
                ""
              )}
              {tipoAtendimento?.nome_atendimento === "Demissão" ? (
                <Demissao id={id} />
              ) : (
                ""
              )}
              {tipoAtendimento?.nome_atendimento === "Admissão" ? (
                <Admissao id={id} />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="Descricao"
                color="secondary"
                label="Descricao do Atendimento"
                name="Descricao"
                value={data.descricao || ""}
                disabled
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                color="secondary"
                label={
                  data.status === "ATIVO"
                    ? "Faça um novo comentário"
                    : "Este atendimento ainda não foi aprovado"
                }
                multiline
                rows="4"
                disabled={data.status === "ATIVO" ? false : true}
                variant="outlined"
                autoFocus
              />
              <br />
              <br />
              <InputLabel
                ref={inputLabel}
                id="demo-simple-select-outlined-label"
                color="secondary"
              >
                Selecione o agente responsável
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={userAgente}
                onChange={handleChangeUser}
                labelwidth={labelWidth}
                color="secondary"
                //disabled={approved === true ? false : true}
              >
                <MenuItem value="-1">
                  <em>Nenhum</em>
                </MenuItem>
                {loginData.map((login) => (
                  <MenuItem key={login.id} value={login.id}>
                    {login.nome}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="inherit"
                onClick={handleReject}
                className={classes.submit}
              >
                Rejeitar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleApproved}
                className={classes.submit}
              >
                Aprovar
              </ButtonNovo>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
