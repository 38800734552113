import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { validate } from "gerador-validador-cpf";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import ButtonNovo from "../../component/ButtonRed";
import Comentarios from "../../component/comentarios";
import Admissao from "../../component/DetalhesAtendimento/Admissao";
import Demissao from "../../component/DetalhesAtendimento/Demissao";
import MudancaFuncao from "../../component/DetalhesAtendimento/MudancaFuncao";
import api from "../../services/api";
import FileUpload from "../upload";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function InteragirAtendimento({ match, history }) {
  const classes = useStyles();

  const [labelWidth] = React.useState(0);

  let id = match.params.id;

  const [data, setData] = useState({});
  const [novoComentario, setNovoComentario] = useState("");
  const [cpf, setCpf] = useState("");
  const [loginData, setLoginData] = useState([]);
  const [userAgente, setUerAgente] = useState("");

  const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

  const agente = dataLocalStorage === null ? false : dataLocalStorage.agente;
  const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;
  const size = agente === true || gestor === true ? 4 : 6;

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`tiposatendimento/${id}/atendimentoid`);

      setData(response.data);
    }
    fetchData();
  }, [id, userAgente]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/login`);

      setLoginData(
        response.data.map((item) => {
          return {
            ...item,
            id: String(item.id),
          };
        })
      );
    }

    fetchData();
  }, [data.user_responsavel]);

  let tipoAtendimento = data.tipo_atendimento || "";

  const handleChangeUser = async (event) => {
    await api.put(`/atendimento/${id}`, {
      atendimento_id: id,
      user_responsavel: event.target.value,
    });

    Swal.fire({
      //position: 'top-start',
      icon: "success",
      title: `Agente Alterado com sucesso.`,
      showConfirmButton: false,
      timer: 2000,
    });
    setUerAgente(event.target.value);
  };

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/dashboard");
  }

  async function handleCloseAtendimento(e) {
    e.preventDefault();

    Swal.fire({
      title: "FINALIZAR ANTEDIMENTO?",
      text: "Realmente deseja finalizer este atendimento?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      reverseButtons: true,
      confirmButtonText: "Sim, Finalizar",
      cancelButtonText: "NÃO",
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }
      api
        .put(`/atendimentoagente/${id}`, {
          status: "FINALIZADO",
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Atendimento Finalizado com sucesso.",
            allowEnterKey: false,
            customClass: {
              container: "my-swal",
            },
          });
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao finalizar o atendimento.",
            customClass: {
              container: "my-swal",
            },
          });
        });
    });

    // await api.put(`/atendimentoagente/${id}`, {
    //   status: 'FINALIZADO',
    // });
  }

  async function handleNewComment(e) {
    e.preventDefault();
    if (novoComentario.length === 0) {
      Toast.fire({
        icon: "error",
        title: "Não é possível fazer comentário com campo em branco ou vazio.",
      });
    }
    if (novoComentario.length !== 0) {
      await api.post(`atendimento/${id}/usuario/${dataLocalStorage.id}`, {
        mensagem: novoComentario,
      });

      window.location.reload(false);
    }
  }

  //const [value, setValue] = React.useState(<Comentarios id={id} />);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleChangeCpf(e) {
    e.preventDefault();
    if (validate(cpf)) {
      await api.put(`/atendimento/${id}`, {
        atendimento_id: id,
        cpf: cpf,
      });

      Toast.fire({
        icon: "success",
        title: "CPF Cadastrado com sucesso.",
      });

      window.location.reload(false);
    } else {
      Toast.fire({
        icon: "error",
        title: "O CPF Informanodo não é valido.",
      });
    }
  }

  //Melhorar logica de usuário logado....
  if (JSON.parse(localStorage.getItem("login")) === null) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          {/*<img src={logo} className="App-logo" alt="logo" height="100" />*/}
          <Typography component="h1" variant="h5">
            Atendimento #{id}
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="Filial"
                  name="Filial"
                  variant="outlined"
                  required
                  fullWidth
                  id="filail"
                  color="secondary"
                  label="Filial"
                  value={data.filial || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="TipoAtendimento"
                  color="secondary"
                  label="Tipo de Atendimento"
                  name="TipoAtendimento"
                  value={tipoAtendimento.nome_atendimento || ""}
                  disabled
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="data"
                  color="secondary"
                  label="Data da solicitação"
                  name="data"
                  value={data.data || ""}
                  disabled
                  autoComplete="data"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  color="secondary"
                  name="Status"
                  label="Status do Atendimento"
                  id="Status"
                  value={data.status || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {tipoAtendimento?.nome_atendimento === "Mudança de função" ? (
                  <MudancaFuncao id={id} />
                ) : (
                  ""
                )}
                {tipoAtendimento?.nome_atendimento === "Demissão" ? (
                  <Demissao id={id} />
                ) : (
                  ""
                )}
                {tipoAtendimento?.nome_atendimento === "Admissão" ? (
                  <Admissao id={id} />
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Descricao"
                  color="secondary"
                  label="Descricao do Atendimento"
                  name="Descricao"
                  value={data.descricao || ""}
                  disabled
                  multiline
                  rows="4"
                  autoComplete="lname"
                />
              </Grid>

              {data.cpf === null ? (
                ""
              ) : (
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    color="secondary"
                    label={
                      data.status === "APROVADO"
                        ? "Faça um novo comentário"
                        : "Este atendimento ainda não foi aprovado"
                    }
                    multiline
                    //value={novoComentario}
                    onChange={(event) => setNovoComentario(event.target.value)}
                    rows="4"
                    disabled={data.status === "APROVADO" ? false : true}
                    variant="outlined"
                    autoFocus
                  />
                  <br />
                  <br />
                  {loginData.length > 0 && gestor && (
                    <>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Alterar o agente responsável
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        // value={userAgente}
                        onChange={handleChangeUser}
                        labelwidth={labelWidth}
                        value={String(data.user_responsavel) || ""}
                        color="secondary"
                        //disabled={approved === true ? false : true}
                      >
                        {/* <MenuItem value="0">
                      <em>Nenhum</em>
                    </MenuItem> */}
                        {loginData.map((login) => (
                          <MenuItem key={login.id} value={login.id}>
                            {login.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </Grid>
              )}
              {data.cpf === null ? (
                ""
              ) : (
                <Grid item xs={12} sm={12}>
                  {/* data.status === "APROVADO" ? value : ""*/}
                </Grid>
              )}

              {data.status === "APROVADO" || data.status === "PENDENTE" ? (
                <Grid item xs={12} sm={12}>
                  {data.cpf === null ? (
                    <TextField
                      id="filled-secondary"
                      label="Preencha o CPF"
                      variant="filled"
                      value={cpf}
                      onChange={(event) => setCpf(event.target.value)}
                      //type="number"
                      color="secondary"
                      inputProps={{ maxLength: 11 }}
                    />
                  ) : (
                    <div className={classes.root}>
                      <AppBar position="static" color="default">
                        <Tabs
                          variant="fullWidth"
                          value={value}
                          onChange={handleChange}
                          aria-label="nav tabs comemmt"
                        >
                          <LinkTab
                            label="Comentários"
                            href="/drafts"
                            {...a11yProps(0)}
                          />
                          <LinkTab
                            label="Enviar arquivos"
                            href="/trash"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <Comentarios id={id} />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <FileUpload
                          cpf={data.cpf}
                          atendimento_id={id}
                          usuario_id={dataLocalStorage.id}
                          sendFile={true}
                        />
                      </TabPanel>
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <FileUpload
                    cpf={data.cpf}
                    atendimento_id={id}
                    usuario_id={dataLocalStorage.id}
                    sendFile={false}
                  />
                </Grid>
              )}

              {data.status === "APROVADO" || data.status === "PENDENTE" ? (
                <>
                  <Grid item xs={12} sm={size}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="inherit"
                      onClick={handleHistoryBack}
                      className={classes.submit}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={size}>
                    <ButtonNovo
                      type="submit"
                      id="buttonPost"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={
                        data.cpf === null ? handleChangeCpf : handleNewComment
                      }
                    >
                      {data.cpf === null ? "Salvar o CPF" : "Comentar"}
                    </ButtonNovo>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="inherit"
                    onClick={handleHistoryBack}
                    className={classes.submit}
                  >
                    Voltar
                  </Button>
                </Grid>
              )}

              {(agente === true || gestor === true) &&
              data.status === "APROVADO" ? (
                <Grid item xs={12} sm={size}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleCloseAtendimento}
                    className={classes.submit}
                  >
                    Finalizar Atendimento
                  </Button>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
