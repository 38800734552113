import DateFnsUtils from "@date-io/date-fns";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Form } from "@unform/web";
import pt from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ButtonNovo from "../../component/ButtonRed";
//import axios from 'axios';
import api from "../../services/api";
import history from "../../services/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//const cookies = new Cookies();

export default function Atendimento(/*{history}*/) {
  const classes = useStyles();

  const [filial, setFilial] = useState("");
  const [admissao, setAdmissao] = useState("");
  const [demissao, setDemissao] = useState("");
  const [mudancaFuncao, setMudancaFuncao] = useState("");
  const [atendimento, setAtendimento] = useState("");
  const [descricao, setDescricao] = useState("");

  const [admissaoCargo, setAdmissaoCargo] = useState("");

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [tiposAtendimento, setTiposAtendimento] = useState([]);
  const [tiposAdmissao, setTiposAdmissao] = useState([]);
  const [tiposDemissao, setTiposDemissao] = useState([]);
  const [tiposMudancaFuncao, setTiposMudancaFuncao] = useState([]);

  const [funcionarioSubstituido, setFuncionarioSubstituido] = useState("");
  const [motivoAdmissao, setMotivoAdmissao] = useState("");

  const [demissaoNomeFuncioario, setDemissaoNomeFuncionario] = useState("");
  const [demissaoMatricula, setdDemissaoMatricula] = useState("");
  const [demissaoFuncao, setDemissaoFuncao] = useState("");
  const [demissaoDataDesligamento, setDemissaoDataDesligamento] = useState(
    new Date()
  );

  const [mudancaFuncaoNome, setMudancaFuncaoNome] = useState("");
  const [mudancaFuncaoMatricula, setdMudancaFuncaoMatricula] = useState("");

  const [listFilial, setListFilial] = useState([]);

  let admissaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Admissão"
  );
  let demissaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Demissão"
  );
  let mudancaFuncaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Mudança de função"
  );

  let AumentoQuadroTemp = tiposAdmissao.find(
    (element) => element.nome_tipo_admissao === "Aumento de quadro temporário"
  );
  let AumentoQuadroEfet = tiposAdmissao.find(
    (element) => element.nome_tipo_admissao === "Aumento de quadro efetivo"
  );

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get("tiposatendimento");

      setTiposAtendimento(response.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataAdmissao() {
      const response = await api.get("tiposadmissao");

      setTiposAdmissao(response.data);
    }

    fetchDataAdmissao();
  }, []);

  useEffect(() => {
    async function fetchDataDemissao() {
      const response = await api.get("tiposDemissao");

      setTiposDemissao(response.data);
    }

    fetchDataDemissao();
  }, []);

  useEffect(() => {
    async function fetchDataMudancaFuncao() {
      const response = await api.get("tiposMudancaFuncao");

      setTiposMudancaFuncao(response.data);
    }

    fetchDataMudancaFuncao();
  }, []);

  useEffect(() => {
    async function filialList() {
      const response = await api.get(`filiais`);

      setListFilial(response.data);
    }

    filialList();
  }, []);

  const handleChangeFilial = (event) => {
    setFilial(event.target.value);
  };

  const handleChangeAdmissao = (event) => {
    setFuncionarioSubstituido("");
    setMotivoAdmissao("");
    setMudancaFuncao("");
    setDemissao("");
    setAdmissao(event.target.value);
  };

  const handleChangeDemissao = (event) => {
    setDemissaoNomeFuncionario("");
    setdDemissaoMatricula("");
    setMudancaFuncao("");
    setAdmissao("");
    //setDemissaoDataDesligamento(new Date());
    setDemissao(event.target.value);
  };

  const handleChangeMucancaFuncao = (event) => {
    setMudancaFuncaoNome("");
    setdMudancaFuncaoMatricula("");
    setAdmissao("");
    setDemissao("");
    setMudancaFuncao(event.target.value);
  };

  const handleChangeAtendimento = (event) => {
    setAtendimento(event.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/dashboard");
  }

  async function handleHistoryAtendimento(e) {
    e.preventDefault();
    console.log("Iniciar");

    if (filial === "") {
      Toast.fire({
        icon: "error",
        title: "O campo filial é obrigatório.",
      });
    } else if (atendimento === "") {
      Toast.fire({
        icon: "error",
        title: "O campo tipo de atendimento é obrigatório.",
      });
    } else if (descricao === "") {
      Toast.fire({
        icon: "error",
        title: "O campo descrição é obrigatório.",
      });
    } else if (
      mudancaFuncao !== "" &&
      (mudancaFuncaoNome === "" || mudancaFuncaoMatricula === "")
    ) {
      Toast.fire({
        icon: "error",
        title:
          mudancaFuncaoNome === ""
            ? "O campo Nome Funcaionário é obrigatório."
            : "O campo Matrícula é obrigatório.",
      });
    } else if (descricao.length > 255) {
      Toast.fire({
        icon: "error",
        title: "O campo descrição não pode ser maior que 255 caracteres",
      });
    } else {
      const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

      const response = await api.post(
        `tiposatendimento/${atendimento}/atendimento`,
        {
          filial: filial,
          descricao: descricao,
          status: "PENDENTE",
          user_solicitante: dataLocalStorage.id,
          user_responsavel: null,
          data: Date.now(),
          sla: null,
          //
          admissao_tipo: admissao,
          admissao_func_subs: funcionarioSubstituido,
          admissao_motivo: motivoAdmissao,
          admissao_cargo: admissaoCargo,
          //
          demissao_tipo: demissao,
          demissao_nome_func: demissaoNomeFuncioario,
          demissao_matricula: demissaoMatricula,
          demissao_data: demissaoDataDesligamento,
          demissao_funcao: demissaoFuncao,
          //
          mudancaFuncao_tipo: mudancaFuncao,
          mudancaFuncao_nome_func: mudancaFuncaoNome,
          mucancaFuncao_matricula: mudancaFuncaoMatricula,
        }
      );

      history.push(`interagiratendimento/${response.data.id}`);

      return response;
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/*<img src={logo} className="App-logo" alt="logo" height="100" />*/}
          <Typography component="h1" variant="h5" style={{ marginTop: 30 }}>
            Iniciar Atendimento
          </Typography>

          <Form className={classes.form} noValidate>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={inputLabel}
                color="secondary"
                aria-label="Selecione a Filial"
                variant="outlined"
              >
                Selecione a Filial
              </InputLabel>

              <Select
                label="Selecione a Filial"
                value={filial}
                onChange={handleChangeFilial}
                labelwidth={labelWidth}
                color="secondary"
                name="filial"
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {listFilial.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={`${item.GRUPO}-${item.COD_FILIAL}`}
                  >
                    {`${item.GRUPO} - ${item.COD_FILIAL} - ${item.NOME_FILIAL}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={inputLabel}
                id="demo-simple-select-outlined-label"
                color="secondary"
              >
                Tipo de Atendimento
              </InputLabel>
              <Select
                label="Tipo de Atendimento"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={atendimento}
                onChange={handleChangeAtendimento}
                labelwidth={labelWidth}
                color="secondary"
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>

                {tiposAtendimento.map((atendimento) => (
                  <MenuItem key={atendimento.id} value={atendimento.id}>
                    {atendimento.nome_atendimento}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {admissaoArray?.id === atendimento ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  id="demo-simple-select-outlined-label"
                  color="secondary"
                >
                  Tipo de movimentação
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  label="Tipo de movimentação"
                  id="demo-simple-select-outlined"
                  value={admissao}
                  onChange={handleChangeAdmissao}
                  labelwidth={labelWidth}
                  color="secondary"
                  name="admissao"
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {tiposAdmissao.map((admissao) => (
                    <MenuItem key={admissao.id} value={admissao.id}>
                      {admissao.nome_tipo_admissao}
                    </MenuItem>
                  ))}
                </Select>

                {!!admissao ? (
                  <>
                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="cargo"
                      color="secondary"
                      label="Cargo"
                      name="cargo"
                      autoFocus
                      value={admissaoCargo}
                      onChange={(event) => setAdmissaoCargo(event.target.value)}
                    />

                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label={
                        AumentoQuadroEfet.id === admissao ||
                        AumentoQuadroTemp.id === admissao
                          ? "Motivo"
                          : "De quem?"
                      }
                      name="user"
                      autoFocus
                      value={
                        AumentoQuadroEfet.id === admissao ||
                        AumentoQuadroTemp.id === admissao
                          ? motivoAdmissao
                          : funcionarioSubstituido
                      }
                      onChange={(event) =>
                        AumentoQuadroEfet.id === admissao ||
                        AumentoQuadroTemp.id === admissao
                          ? setMotivoAdmissao(event.target.value)
                          : setFuncionarioSubstituido(event.target.value)
                      }
                    />
                  </>
                ) : (
                  ""
                )}
              </FormControl>
            ) : (
              ""
            )}

            {demissaoArray?.id === atendimento ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  id="demo-simple-select-outlined-label"
                  color="secondary"
                >
                  Tipo de movimentação
                </InputLabel>
                <Select
                  label="Tipo de movimentação"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={demissao}
                  onChange={handleChangeDemissao}
                  labelwidth={labelWidth}
                  color="secondary"
                  name="demissao"
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {tiposDemissao.map((demissao) => (
                    <MenuItem key={String(demissao.id)} value={demissao.id}>
                      {demissao.nome_tipo_demissao}
                    </MenuItem>
                  ))}
                </Select>

                {!!demissao ? (
                  <>
                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label="Nome Funcionário"
                      name="user"
                      autoFocus
                      value={demissaoNomeFuncioario}
                      onChange={(event) =>
                        setDemissaoNomeFuncionario(event.target.value)
                      }
                    />

                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label="Função"
                      name="user"
                      autoFocus
                      value={demissaoFuncao}
                      onChange={(event) =>
                        setDemissaoFuncao(event.target.value)
                      }
                    />

                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label="Matrícula"
                      name="user"
                      autoFocus
                      value={demissaoMatricula}
                      onChange={(event) =>
                        setdDemissaoMatricula(event.target.value)
                      }
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // //margin="normal"
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Data demissão"
                        format="dd/MM/yyyy"
                        value={demissaoDataDesligamento}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setDemissaoDataDesligamento(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                ) : (
                  ""
                )}
              </FormControl>
            ) : (
              ""
            )}

            {mudancaFuncaoArray?.id === atendimento ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  id="demo-simple-select-outlined-label"
                  color="secondary"
                >
                  Tipo de movimentação
                </InputLabel>

                <Select
                  label="Tipo de movimentação"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={mudancaFuncao}
                  onChange={handleChangeMucancaFuncao}
                  labelwidth={labelWidth}
                  color="secondary"
                  name="admissao"
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {tiposMudancaFuncao.map((mudancaFuncao) => (
                    <MenuItem key={mudancaFuncao.id} value={mudancaFuncao.id}>
                      {mudancaFuncao.nome_tipo_mudanca_funcao}
                    </MenuItem>
                  ))}
                </Select>

                {!!mudancaFuncao ? (
                  <>
                    <TextField
                      variant="outlined"
                      //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label="Nome Funcionário"
                      name="user"
                      autoFocus
                      value={mudancaFuncaoNome}
                      onChange={(event) =>
                        setMudancaFuncaoNome(event.target.value)
                      }
                    />

                    <TextField
                      variant="outlined"
                      //margin="normal"
                      required
                      fullWidth
                      id="user"
                      color="secondary"
                      label="Matrícula"
                      name="user"
                      autoFocus
                      value={mudancaFuncaoMatricula}
                      onChange={(event) =>
                        setdMudancaFuncaoMatricula(event.target.value)
                      }
                    />
                  </>
                ) : (
                  ""
                )}
              </FormControl>
            ) : (
              ""
            )}

            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                //inputProps={{
                //  maxLength: 255,
                //}}
                variant="outlined"
                //margin="normal"
                required
                fullWidth
                id="user"
                color="secondary"
                label="Descrição"
                name="user"
                autoComplete="Descrição"
                autoFocus
                value={descricao}
                multiline
                rows={4}
                onChange={(event) => setDescricao(event.target.value)}
              />

              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleHistoryAtendimento}
                className={classes.submit}
              >
                Iniciar
              </ButtonNovo>
              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleHistoryBack}
                className={classes.submit}
              >
                Voltar
              </ButtonNovo>
            </FormControl>
          </Form>
        </div>
      </Container>
    </>
  );
}
