import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';


 import api from '../../services/api';


export default function InteragirAtendimento({id}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`admissao/${id}`);
      setData(response.data);  
     
    }
    fetchData(); 
  }, [id]);

  return (
  
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Cargo"
        name="Cargo"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Cargo"
        value={data[0]?.cargo || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Matricula"
        name="Matricula"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label={data[0]?.funcionario_subs === "" ? "Motivo" : "Nome Funcionário"}
        value={data[0]?.funcionario_subs === "" ? data[0]?.motivo : data[0]?.funcionario_subs || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextField
        autoComplete="Tipo de Movimentação"
        name="Tipo de Movimentação"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Tipo de Movimentação"
        value={data[0]?.tipo_admissao?.nome_tipo_admissao || ""}
        disabled  
      />
    </Grid>
  </Grid>
  
  ) 
}