import React, { Component } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";

import api from "../../services/api";

//import GlobalStyle from "./styles/global";
import { Container, Content } from "./styles";

import Upload from "../../component/Upload";
import FileList from "../../component/FileList";

class FileUpload extends Component {
  state = {
    uploadedFiles: []
  };

  
  
  //const [data, setData] = useState({});

  async componentDidMount() {
    const response = await api.get(`atendimento/${this.props.atendimento_id}/files`);
    this.setState({
      uploadedFiles: response.data.map(file => ({
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: file.url,
        uploaded: true,
        url: file.url,
        data: file.date,
        user: file.usuario.nome,
        id_api: file.id
      }))
    });
  }

  handleUpload = files => {
    const dataLocalStorage = JSON.parse(localStorage.getItem('login'));

    const dataHoje = new Date();
    const dia = dataHoje.getDate(); 
    const mes = dataHoje.getMonth();
    const ano = dataHoje.getFullYear();
    const str_data =("0"+dia).slice(-2) +'/'+(mes+1)+'/'+ ano;

    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      cpf: this.props.cpf,
      user: dataLocalStorage.nome,
      data: str_data,
      user_id: this.props.usuario_id
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    });
  };
 
  processUpload = uploadedFile => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);
   
    api
      .post(`atendimento/${this.props.atendimento_id}/files/${this.props.cpf}/user/${this.props.usuario_id}`, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress
          });
        }
      })
      .then(response => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          cpf: this.props.cpf,
          user_id: this.props.usuario_id
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true
        });
      });
  };

  handleDelete = async (id, id_api) => {
   
    const id_delete = typeof id_api === "undefined" ? id : id_api;

    try {
      api.delete(`files/${id_delete}`);
    } catch (error) {
      console.log(error)
    }

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
    });
  };
    

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }

  render() {
    const { uploadedFiles } = this.state;
    //const [uploadedFiles, setUploadedFiles] = useState([]);

    return (
      <Container>
        <Content>
          {this.props.sendFile === true ? <Upload onUpload={this.handleUpload} /> : "" }
          
          {!!uploadedFiles.length && (
            <FileList files={uploadedFiles} onDelete={this.handleDelete} onDelFile={this.props.sendFile} />
          )}
        </Content>
        {/*<GlobalStyle />*/}
      </Container>
    );
  }
}

export default FileUpload;
