import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';


 import api from '../../services/api';


export default function InteragirAtendimento({id}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`demissao/${id}`);
      setData(response.data);  
     
    }
    fetchData(); 
  }, [id]);

  return (
  
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Nome Funcaionário"
        name="Nome Funcaionário"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Nome Funcaionário"
        value={data[0]?.nome || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Matricula"
        name="Matricula"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Matricula"
        value={data[0]?.matricula || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Função"
        name="Função"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Função"
        value={data[0]?.funcao || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        autoComplete="Data Desligamento"
        name="Data Desligamento"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Data Desligamento"
        value={data[0]?.data_desligamento || ""}
        disabled  
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextField
        autoComplete="Tipo de dispensa"
        name="Tipo de dispensa"
        variant="outlined"
        required
        fullWidth
        id="filail"
        color="secondary"
        label="Tipo de dispensa"
        value={data[0]?.tipo_demissao?.nome_tipo_demissao || ""}
        disabled  
      />
    </Grid>
  </Grid>
  
  ) 
}